<h3 class="py-2">Import new reference data into flux search</h3>

<h5>Beware! This will replace all existing data of the selected type.</h5>

<form ngNoForm enctype="multipart/form-data" action="/api/refdata/flux-import" method="post">
  <div class="row py-4">
    <label class="col-auto">
      Type:
      <select name="index">
        <option value="visits">Visit Summaries</option>
        <option value="refdata_vessel">Vessels</option>
        <option value="refdata_carrier">Carriers</option>
        <option value="refdata_port">Ports</option>
        <option value="refdata_portofcall">Ports of Call</option>
        <option value="refdata_location">Locations</option>
        <option value="refdata_berth">Berths</option>
        <option value="refdata_terminal">Terminals</option>
        <option value="refdata_portfacility">Port Facilities</option>
        <option value="refdata_pilotexemption">Pilot Exemptions</option>
        <option value="refdata_dangerousgood">Dangerous goods</option>
        <option value="refdata_dangerinformation">Danger information (mli)</option>
        <option value="refdata_wastematerial">Waste Materials</option>
        <option value="refdata_bunkerproduct">Bunker Products</option>
        <option value="refdata_bunkerproductsupplier">Bunker Product Suppliers</option>
        <option value="refdata_bunkerlicense">Bunker Product License</option>
        <option value="refdata_sizetype">Size types</option>
        <option value="refdata_goodsclassification">Goods classifications</option>
        <option value="refdata_party">Parties</option>
        <option value="refdata_warehouselicense">Warehouse licenses</option>
        <option value="refdata_vesselservice">Vessel services</option>
        <option value="user-preferences">User Preferences</option>
        <option value="refdata_transittemplate">Transit templates (all at once)</option>
        <option value="refdata_terminalemail">Terminal Email</option>
        <option value="refdata_exemptionreferencedata">Exemptions</option>
      </select>
    </label>
    <div class="col-auto">
      <input type="file" name="data"/>
    </div>
    <div class="col-auto">
      <input type="submit" value="Upload">
    </div>
  </div>
</form>
