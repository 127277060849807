<ng-container *ngIf="context.visit">
  <div *ngIf="mobile else desktopMenu">
    <ng-container *ngIf="!context.visit.portOfCall.authoriseOrganisationsDisabled">
      <app-declaration-status tooltipTitle="Authorise Organisations" icon="fas fa-unlock-alt"
                              [status]="getAuthoriseStatus()" [link]="'/details/' + visit.crn + '/authorise'">
        Authorise Organisations
      </app-declaration-status>
    </ng-container>
    <ng-container *ngIf="!context.isUserOnlyCargoDeclarant() && visit.portOfCall.harbourDuesEnabled &&
    !context.visitInTerminalPlanning()">
      <app-declaration-status tooltipTitle="Financial Declarant" [status]="getFinancialDeclarantStatus()"
                              icon="fas fa-euro-sign" [link]="'/details/' + visit.crn + '/financialDeclarant'">
        Financial Declarant
      </app-declaration-status>
    </ng-container>
    <ng-container>
      <app-declaration-status [status]="getVisitStatus() && !appContext.isCustoms()" tooltipTitle="Visit Information"
                              icon="fas fa-route" [link]="'/details/' + visit.crn + '/visit'">
        Visit Information
      </app-declaration-status>
    </ng-container>
    <ng-container *ngIf="!context.isUserOnlyCargoDeclarant() && portOfCallAllowsSecurity(visit.portOfCall) &&
    !appContext.isCustoms() && !context.visitInTerminalPlanning()">
      <app-declaration-status tooltipTitle="Security" icon="fas fa-shield-alt" [status]="getSecurityStatus()"
                              [link]="'/details/' + visit.crn + '/security'">
        Security
      </app-declaration-status>
    </ng-container>
    <ng-container *ngIf="!context.isUserOnlyCargoDeclarant() && appContext.hasRole('PaxDeclarant') &&
    !context.visitInTerminalPlanning()">
      <app-declaration-status tooltipTitle="Crew and Passengers" icon="fas fa-address-card" [status]="getStatus('PAX')"
                              [link]="'/details/' + visit.crn + '/passengers'">
        Crew and Passengers
      </app-declaration-status>
    </ng-container>
    <ng-container *ngIf="context.isUserOnlyCargoDeclarant() && (visit.portOfCall.port.locationUnCode === 'NLRTM' ||
    visit.portOfCall.port.locationUnCode === 'NLAMS') && !context.visitInTerminalPlanning()">
      <app-declaration-status tooltipTitle="Health declaration" icon="fas fa-medkit" [status]="getStatus('HEALTH')"
                              [link]="'/details/' + visit.crn + '/health'">
        Health
      </app-declaration-status>
    </ng-container>
    <ng-container *ngIf="!context.isUserOnlyCargoDeclarant() && visit.portOfCall.wasteEnabled &&
    !appContext.isCustoms() && !context.visitInTerminalPlanning()">
      <app-declaration-status tooltipTitle="Waste" icon="fas fa-recycle" [status]="getStatus('WASTE')"
                              [link]="'/details/' + visit.crn + '/waste'">
        Waste
      </app-declaration-status>
    </ng-container>
    <ng-container *ngIf="!context.isUserOnlyCargoDeclarant() && (appContext.hasRole('ShipSuppliesDeclarant') ||
    appContext.isCustoms()) && !context.visitInTerminalPlanning()">
      <app-declaration-status tooltipTitle="Ship Stores" icon="fas fa-wine-bottle" [status]="getStatus('STO')"
                              [link]="'/details/' + visit.crn + '/shipStores'">
        Ship Stores
      </app-declaration-status>
    </ng-container>
    <div *ngIf="(appContext.isAdminOrCustoms() || (visit.cargoImportEnabled &&
    context.isUserCargoImportViewer())) && !context.visitInTerminalPlanning()" class="position-relative">
      <app-declaration-status tooltipTitle="Cargo Import" [status]="getStatus('SDT') || getStatus('ENS')"
                              icon="fas fa-caret-square-down" [link]="useNewCargoScreen ? ('/cargo/' + visit.crn) : ('/details/' + visit.crn + '/cargoImport')"
                              [openInNewTab]="useNewCargoScreen">
        Cargo Import
      </app-declaration-status>
      <ng-container *ngIf="useNewCargoScreen">
        <i class="position-absolute top-0 end-0 fa fa-arrow-up fa-rotate-by" style="--fa-rotate-angle: 45deg; font-size: 0.75rem; margin-right: 1px;" aria-hidden="true"></i>
      </ng-container>
    </div>
    <ng-container *ngIf="visit.dangerousGoodsEnabled && !appContext.isCustoms() && !context.visitInTerminalPlanning()">
      <app-declaration-status tooltipTitle="Dangerous Goods" [status]="getStatus('DANGEROUS_GOODS')" icon="fas fa-burn"
                              [link]="getDangerousGoodsLink()">
        Dangerous Goods
      </app-declaration-status>
    </ng-container>
  </div>
  <ng-template #desktopMenu>
    <div class="navbar-expand justify-content-center d-none d-sm-block always-enabled" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li *ngIf="!context.visit.portOfCall.authoriseOrganisationsDisabled"
            class="nav-item border border-white rounded-start" title="Authorise Organisations">
          <app-declaration-status *ngIf="context.isOrganisationCurrentVisitDeclarant()"
                                  tooltipTitle="Authorise Organisations" icon="fas fa-unlock-alt"
                                  [status]="getAuthoriseStatus()" [link]="'/details/' + visit.crn + '/authorise'">
            Authorise Organisations
          </app-declaration-status>
        </li>
        <li *ngIf="!context.isUserOnlyCargoDeclarant() && visit.portOfCall.harbourDuesEnabled &&
        !context.visitInTerminalPlanning()" class="nav-item border border-white border-start-0"
            title="Financial declarant">
          <app-declaration-status tooltipTitle="Financial declarant" [status]="getFinancialDeclarantStatus()"
                                  icon="fas fa-euro-sign" [link]="'/details/' + visit.crn + '/financialDeclarant'">
            Financial Declarant
          </app-declaration-status>
        </li>
        <li class="nav-item border border-white border-start-0" title="Visit information">
          <div>
            <app-clearance-status [clearanceKey]="'ekh'" [declarationStatus]="getVisitStatus()"></app-clearance-status>
            <app-visit-declaration-status [visitDeclarationStatus]="getVisitStatus()"
                                          [terminalMessageStatus]="getLeastTerminalMessageStatus()">
            </app-visit-declaration-status>
          </div>
        </li>
        <li *ngIf="!context.isUserOnlyCargoDeclarant() && portOfCallAllowsSecurity(visit.portOfCall) &&
        !appContext.isCustoms() && !context.visitInTerminalPlanning()"
            class="nav-item border border-white border-start-0" title="Security">
          <div>
            <app-clearance-status [clearanceKey]="'sspi'"
                                  [declarationStatus]="getSecurityStatus()"></app-clearance-status>
            <app-declaration-status tooltipTitle="Security" icon="fas fa-shield-alt" [status]="getSecurityStatus()"
                                    [link]="'/details/' + visit.crn + '/security'">
              Security
            </app-declaration-status>
          </div>
        </li>
        <li *ngIf="!context.isUserOnlyCargoDeclarant() && appContext.hasRole('PaxDeclarant') &&
        !context.visitInTerminalPlanning()" class="nav-item border border-white border-start-0"
            title="Crew and passengers">
          <app-declaration-status tooltipTitle="Crew and passengers" icon="fas fa-address-card"
                                  [status]="getStatus('PAX')" [link]="'/details/' + visit.crn + '/passengers'">
            Crew and Passengers
          </app-declaration-status>
        </li>
        <li *ngIf="!context.isUserOnlyCargoDeclarant() && (visit.portOfCall.port.locationUnCode === 'NLRTM' ||
        visit.portOfCall.port.locationUnCode === 'NLAMS') && !context.visitInTerminalPlanning()"
            class="nav-item border border-white border-start-0" title="Health">
          <app-clearance-status [clearanceKey]="'mdoh'" [declarationStatus]="getStatus('HEALTH')">
          </app-clearance-status>
          <app-declaration-status *ngIf="appContext.isClearancesActive()" tooltipTitle="Health" icon="fas fa-medkit"
                                  [status]="getStatus('HEALTH')" [link]="'/details/' + visit.crn + '/health'">
            Health
          </app-declaration-status>
          <app-declaration-status-health *ngIf="!appContext.isClearancesActive()" tooltipTitle="Health"
                                         icon="fas fa-medkit" [status]="getStatus('HEALTH')"
                                         [hamisHealthStatus]="getHamisHealthStatus()"
                                         [link]="'/details/' + visit.crn + '/health'">
            Health
          </app-declaration-status-health>
        </li>
        <li *ngIf="!context.isUserOnlyCargoDeclarant() && visit.portOfCall.wasteEnabled && !appContext.isCustoms() &&
        !context.visitInTerminalPlanning()" class="nav-item border border-white border-start-0" title="Waste">
          <div>
            <app-clearance-status [clearanceKey]="'emas'" [declarationStatus]="getStatus('WASTE')">
            </app-clearance-status>
            <app-declaration-status tooltipTitle="Waste" icon="fas fa-recycle" [status]="getStatus('WASTE')"
                                    [link]="'/details/' + visit.crn + '/waste'">
              Waste
            </app-declaration-status>
          </div>
        </li>
        <li *ngIf="!context.isUserOnlyCargoDeclarant() && (appContext.hasRole('ShipSuppliesDeclarant') ||
        appContext.isCustoms()) && !context.visitInTerminalPlanning()"
            class="nav-item border border-white border-start-0" title="Ship stores">
          <app-declaration-status tooltipTitle="Ship stores" icon="fas fa-wine-bottle" [status]="getStatus('STO')"
                                  [link]="'/details/' + visit.crn + '/shipStores'">
            Ship Stores
          </app-declaration-status>
        </li>
        <li *ngIf="(appContext.isAdminOrCustoms() || (visit.cargoImportEnabled && context.isUserCargoImportViewer())) &&
        !context.visitInTerminalPlanning()" class="nav-item border border-white border-start-0 position-relative" title="Cargo import">
          <app-declaration-status tooltipTitle="Cargo import" [status]="getStatus('SDT') || getStatus('ENS')"
                                  icon="fa fa-caret-square-down" [link]="useNewCargoScreen ? ('/cargo/' + visit.crn) : ('/details/' + visit.crn + '/cargoImport')"
                                  [openInNewTab]="useNewCargoScreen">
            Cargo Import
          </app-declaration-status>
          <ng-container *ngIf="useNewCargoScreen">
            <i class="position-absolute top-0 end-0 fa fa-arrow-up fa-rotate-by" style="--fa-rotate-angle: 45deg; font-size: 0.75rem; margin-right: 1px;" aria-hidden="true"></i>
          </ng-container>
        </li>
        <li *ngIf="visit.dangerousGoodsEnabled && !appContext.isCustoms() && !context.visitInTerminalPlanning()"
            class="nav-item border border-white border-start-0 rounded-end" title="Dangerous goods">
          <div>
            <app-clearance-status [clearanceKey]="'emgs'" [declarationStatus]="getStatus('DANGEROUS_GOODS')">
            </app-clearance-status>
            <app-declaration-status tooltipTitle="Dangerous goods" [status]="getStatus('DANGEROUS_GOODS')"
                                    icon="fas fa-burn" [link]="getDangerousGoodsLink()">
              Dangerous Goods
            </app-declaration-status>
          </div>
        </li>

        <li class="nav-item dropdown ms-2">
          <a class="nav-link dropdown-toggle text-dark" href="#" id="navbarDropdownMenuLink" role="button"
             data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            More
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style="z-index: 999999;">
            <!-- Support -->
            <a class="dropdown-item" [href]="appContext.supportPageForTask" target="_blank" tabindex="-1">
              <i class="fa fa-headset me-2" aria-hidden="true"
                 [title]="(appContext.supportPageTooltip | titlecase) + ' support'"></i>Support
            </a>
            <!-- Visit History/Activity -->
            <a class="dropdown-item" href="#" (click)="$event.preventDefault()"
               [routerLink]="['/details/' + visit.crn + '/history']">
              <i class="fas fa-history me-2" aria-hidden="true" title="Event history"></i>History
            </a>
            <!-- Downloads -->
            <ng-container *ngIf="!context.isOrganisationOnlyCargoDeclarant() &&
            (this.context.findLatestDeclaration(PAX) || this.context.findLatestDeclaration(STO)) ||
            this.displayNotificationOfDeparture()">
              <div class="dropdown-divider"></div>
              <h6 class="dropdown-header" style="font-size: 17px;">
                <i class="fa fa-file-download me-1" aria-hidden="true"></i> Downloads
              </h6>
              <a *ngIf="(this.context.findLatestDeclaration(PAX) || this.context.findLatestDeclaration(STO))
                && !context.isOrganisationOnlyCargoDeclarant() && !appContext.isCustoms()" class="dropdown-item"
                 href="#" (click)="$event.preventDefault()">
                <app-confirmation-of-purchase></app-confirmation-of-purchase>
              </a>
              <a *ngIf="this.displayNotificationOfDeparture() && !context.isOrganisationOnlyCargoDeclarant() &&
              !appContext.isCustoms()" class="dropdown-item" href="#" (click)="$event.preventDefault()">
                <app-notification-of-departure></app-notification-of-departure>
              </a>
            </ng-container>
            <ng-container *ngIf="!visit.terminalPlanningEnabled || visit.terminalPlanningApproved">
              <!-- Template downloads -->
              <div class="dropdown-divider"></div>
              <h6 class="dropdown-header" style="font-size: 17px;">
                Excel templates
              </h6>
              <a href="/assets/templates/security-declaration-template-v2.0.xls" class="dropdown-item">
                <i class="far fa-file-excel me-1" aria-hidden="true"></i> Security
              </a>
              <a href="/assets/templates/sto-declaration-template-v1.1.xls" class="dropdown-item">
                <i class="far fa-file-excel me-1" aria-hidden="true"></i> Ship stores
              </a>
              <a href="/assets/templates/waste-declaration-template-v2.0.xls" class="dropdown-item">
                <i class="far fa-file-excel me-1" aria-hidden="true"></i> Waste
              </a>
              <a href="/assets/templates/Maritime-Declaration-of-Health-template-v1.1.xls" class="dropdown-item">
                <i class="far fa-file-excel me-1" aria-hidden="true"></i> Health
              </a>
              <a href="/assets/templates/pax-declaration-template-small-v1.5.xls" class="dropdown-item">
                <i class="far fa-file-excel me-1" aria-hidden="true"></i> Passengers (small)
              </a>
              <a href="/assets/templates/pax-declaration-template-large-v1.5.xls" class="dropdown-item">
                <i class="far fa-file-excel me-1" aria-hidden="true"></i> Passengers (large)
              </a>
              <a href="/assets/templates/dangerous-goods-declaration-template-v1.0.xls" class="dropdown-item">
                <i class="far fa-file-excel me-1" aria-hidden="true"></i> Dangerous goods
              </a>
            </ng-container>
          </div>
        </li>
      </ul>
    </div>
  </ng-template>
</ng-container>
