<app-transit-declaration-admin *ngIf="appContext.isAdmin()" [declaration]="declaration"></app-transit-declaration-admin>
<div class="ms-4" *ngIf="getAnyRejectReason() as rejectReason">
  <app-status-alert type="danger">
    <div>Customs: {{ rejectReason }}</div>
  </app-status-alert>
</div>

<div class="row mb-2">
  <ng-container *ngIf="isEditable(); else notEditable">
    <div class="col-md ms-4">
      <app-form-group label="Use template">
        <app-search [ngModel]="null" [minCharacters]="0"
                    (ngModelChange)="($event ? useTemplate($event) : null);"
                    [searchFunction]="findTransitTemplates" [inputFormatter]="refData.transitTemplateFormatter">
        </app-search>
      </app-form-group>
    </div>
    <div class="col-md">
    </div>
    <div class="col-auto ms-auto">
      <div class="btn-group">
        <button (click)="send()" class="btn btn-outline-info" tabindex="-1">Save and send</button>
        <button type="button" class="btn btn-outline-info dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown"></button>
        <div class="dropdown-menu dropdown-menu-end">
          <button class="dropdown-item" (click)="save()">Save</button>
          <button *ngIf="!declaration['isNewTransitDeclaration']" class="dropdown-item text-danger"
                  data-bs-toggle="modal" data-bs-target="#cancelTransit">Cancel
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #notEditable>
    <div class="col-auto ms-auto">
      <ng-container *ngIf="isCancellable()">
        <button class="btn btn-outline-danger" data-bs-toggle="modal" data-bs-target="#cancelTransit">
          <i class="fa fa-ban"></i> Cancel declaration
        </button>
      </ng-container>
      <span *ngIf="!!declaration.status.accept">
        <button class="btn btn-outline-info ms-2 me-1" (click)="createNext()"
                style="min-width: 10.8rem">Create next <i class="fa fa-angle-right"></i></button>
      </span>
      <button class="btn btn-outline-info ms-2 me-1" (click)="copy()" style="min-width: 10.8rem">Copy declaration
        <i class="fa fa-angle-right"></i></button>
    </div>

  </ng-template>
</div>

<div class="ms-4 position-relative">
  <app-tab-panel>
    <app-tab-item [templateRef]="detailsRef">
      <span class="tab-item" [ngClass]="{'ng-invalid text-invalid' : !!declaration['ngDetailsInvalid']}">Details</span>
      <ng-template #detailsRef>
        <div class="p-3 border border-top-0 rounded-bottom" [appValidator]="declaration"
             validatorProperty="ngDetailsInvalid">
          <app-transit-details [declaration]="declaration" [isSimplifiedProcedure]="!declaration.data ||
          declaration.data.customsSubPlace == null || declaration.data.transportTerm != null"></app-transit-details>
          <div>
            <div class="declaration-block my-4">
              <h2>Save as new template</h2>
            </div>
          </div>

          <div class="row">
            <div class="col-md">
              <app-form-group label="Template name">
                <input class="form-control" placeholder="Enter template name"
                       [(ngModel)]="declaration.data.templateName">
              </app-form-group>
            </div>

            <div class="col-md">
              <div *ngIf="declaration.data.templateName" class="row align-items-center">
                <div class="col-auto ms-auto">
                  <ul class="nav">
                    <li class="nav-item">
                      <button class="btn btn-info" (click)="$event.preventDefault(); saveTemplate()">
                        Save transit template
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </app-tab-item>
    <app-tab-item [templateRef]="goodsRef">
      <span class="tab-item" [ngClass]="{'ng-invalid text-invalid' : !!declaration['ngGoodsInvalid']}">Goods</span>
      <ng-template #goodsRef>
        <div>
          <ul *ngIf="isEditable() && !hasMultipleHouseConsignments" class="nav position-absolute"
              style="top: 0; right: 0">
            <li class="nav-item">
              <a href="#" class="nav-link pe-1" (click)="$event.preventDefault(); addEmptyGoodsItem()">
                <span class="fa fa-plus-circle"></span><span class="ps-2 d-none d-sm-inline">Add good</span></a>
            </li>
          </ul>
          <div class="p-3 border border-top-0 rounded-bottom" [appValidator]="declaration"
               validatorProperty="ngGoodsInvalid">
            <app-transit-goods [declaration]="declaration"></app-transit-goods>
          </div>
        </div>
      </ng-template>
    </app-tab-item>
    <app-tab-item [templateRef]="historyRef">
      <span class="tab-item">History</span>
      <ng-template #historyRef>
        <div class="p-3 border border-top-0 rounded-bottom">
          <app-transit-history [lrn]="declaration.lrn"></app-transit-history>
        </div>
      </ng-template>
    </app-tab-item>
    <app-tab-item *ngIf="declaration.status.infoRequest?.date" [templateRef]="proofOfArrivalRef">
      <span class="tab-item">Proof Of Arrival</span>
      <ng-template #proofOfArrivalRef>
        <div class="p-3 border border-top-0 rounded-bottom">
          <app-transit-proof-of-arrival [declaration]="declaration"></app-transit-proof-of-arrival>
        </div>
      </ng-template>
    </app-tab-item>
  </app-tab-panel>
</div>

<div id="cancelTransit" class="modal fade" tabindex="-1" role="dialog" #cancelModal>
  <div class="modal-dialog" role="document">
    <div *ngIf="!declaration.status.sent?.date || !!declaration.status.reject; else knownWithCustoms"
         class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Cancel transit declarations</h5>
      </div>
      <div class="modal-body">
        <p>Are you sure that you want to cancel this declaration?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">No
        </button>
        <button type="button" class="btn btn-primary" (click)="cancel(null, cancelModal)" data-bs-dismiss="modal">Yes
        </button>
      </div>
    </div>
    <ng-template #knownWithCustoms>
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Send cancel request to customs</h5>
        </div>
        <div class="modal-body">
          <input id="rejectTransit-reason" type="text" class="form-control" [ngModel]="null"
                 placeholder="Please enter a reason" required>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary me-auto" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" (click)=
            "cancel(appContext.getDocument().getElementById('rejectTransit-reason')['value'], cancelModal)">Send
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
